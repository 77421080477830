<template>

  <div id="order">
    <oneOrder v-if='$store.state.customer_info.isPhoen || $store.state.config.pc_template === 2' :name='name' :key="name"> </oneOrder>
    <onePcPrder v-if='!$store.state.customer_info.isPhoen && $store.state.config.pc_template === 1' :name='name' :key="name"> </onePcPrder>

  </div>
</template>

<script>
import { request } from '@/network/request.js'
import { cookieParse } from "@/network/util";
import oneOrder from '@/views/Template/order/one_order'
import onePcPrder from '@/views/Template/order/one_pc_order'
export default {
  name: 'order',
  components: {
    oneOrder,
    onePcPrder,

  },
  data() {
    return {
      name: null,
    }
  },
  async beforeCreate() {
    if (this.$route.params.name != undefined) {
      this.name = this.$route.params.name
    }
  },
  async created() {
   
  },
  methods: {

  },




}
</script>

<style lang="scss" scoped>
</style>
