<template>

  <div id="one_order">

    <van-nav-bar left-arrow left-text="返回" right-text="缓存查单" @click-left="onClickLeft" @click-right="onClickRight" />
    <div class='one_order'>

      <van-row class="queryBox">
        <van-search v-model="orderAndPayId" shape="round" background="var(--main_color)" placeholder="请输入支付宝交易号或订单号" />

        <van-button type="primary" size="large" class="queryBox-buttom" @click="query()" color="var(--main_color)">
          <h3>查 询</h3>
        </van-button>

        <van-cell center title="是否需要cookie(软件使用)" v-if="$store.state.config.isCookie !== 0">
          <template #right-icon>
            <van-switch v-model="$store.state.config.isCookie" size="24" @click="changeIsCookie" :active-value='2' :inactive-value='1' active-color="var(--main_color)" />
          </template>
        </van-cell>

        <van-row class="cardMessage">
          <van-field v-model="cards" rows="8" label="卡密信息" type="textarea" placeholder="卡密信息在这里显示" />
        </van-row>
      </van-row>

      <div v-if="order_info" class='order_info'>
        <van-cell title="商品名称" :label="order.goodName" />
        <van-row type="flex" justify="space-around">
          <van-col :span='5'>
            <van-cell title="购买数量" :label="order.count" />
          </van-col>
          <van-col :span='5'>
            <van-cell title="支付方式" :label="order.payMode" />
          </van-col>

          <van-col :span='4.5'>
            <van-cell title="单价" :label="'￥'+order.orderPrice/order.count" />
          </van-col>
          <van-col :span='4.5'>
            <van-cell title="总价" :label="'￥'+order.orderPrice" />
          </van-col>
          <van-col :span='5'>
            <van-cell title="付款状态" :label="order.status" />
          </van-col>
        </van-row>

        <van-row>
          <van-col :span='12'>
            <van-cell title="订单号" :label="order.name" />
          </van-col>
          <van-col :span='12'>
            <van-cell title="创建时间" :label="order.createdAt" />

          </van-col>
        </van-row>
        <van-cell title="交易号" :label="order.payNo" />
      </div>

    </div>

  </div>
</template>

<script>
import { request } from '@/network/request.js'
import { cookieParse } from "@/network/util";
export default {
  name: 'one_order',
  components: {

  },
  data() {
    return {
      orderAndPayId: '',
      cards: '',
      order: {},
      order_info: false,
    }
  },
  methods: {
    async query() {
      this.cards = ''
      this.$store.state.loading = true
      let res = await request({
        url: '/order/getById/p',
        data: {
          name: this.orderAndPayId,
        }
      })
      this.order = res.data.data
      if (res.data.no !== 0) return this.$notify({ title: '查询失败', message: '订单号不存在', type: 'error', })
      this.order_info = true
      if (this.order.status == '未支付') return this.$notify({ title: '查询成功', message: '该订单未支付', type: 'warning', })
      this.$notify({ title: '查询成功', message: 'ok', type: 'success', })


      // 卡密数组转换成字符串
      res.data.data.good_cards.forEach((i) => {
        let str = i.cardNumber.replace(/[\r\n]/g, '') //清除换行
        if (this.$store.state.config.isCookie === 2) {
          let c = cookieParse(str)
          if (c.password != undefined) str = `${c.userName}----${c.password}----${c.emailName}----${c.emailPass}`

        }
        this.cards = this.cards.concat(str + '\n')
      })
    },
    changeIsCookie() {
      this.query()
    },
    onClickLeft() {
      this.$router.push('/')
    },
    onClickRight() {
      this.$router.push('/cache_query')
    }
  },
  async created() {
    if (this.$route.params.name != undefined) {
      this.orderAndPayId = this.$route.params.name
      await this.query()
    }





  },
}
</script>

<style lang="scss" scoped>
.queryBox-buttom {
  margin-top: 20px;
}
.cardMessage {
  margin-top: 20px;
}

.one_order {
  padding: 10px;
}
</style>
